const langMenu = document.querySelector("li.custom-dropdown");
const langMenuTrigger = document.querySelector("li.custom-dropdown > a");
let langMenuClosed = true;

if (langMenu) {
  langMenuTrigger.addEventListener("click", (e) => {
    e.preventDefault;
    if (langMenuClosed) {
      langMenu.classList.add("opened");

      return (langMenuClosed = false);
    } else {
      langMenu.classList.remove("opened");
      return (langMenuClosed = true);
    }
  });

  langMenu.addEventListener("mouseover", (e) => {
    langMenu.classList.add("opened");
    return (langMenuClosed = false);
  });

  langMenu.addEventListener("mouseout", (e) => {
    langMenu.classList.remove("opened");
    return (langMenuClosed = true);
  });
}
