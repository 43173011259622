(function ($) {
  /**
   * initMap
   *
   * Renders a Google Map onto the selected jQuery element
   *
   * @date    22/10/19
   * @since   5.8.6
   *
   * @param   jQuery $el The jQuery element.
   * @return  object The map instance.
   */
  function initMap($el) {
    // Find marker elements within map.
    var $markers = $el.find(".marker");

    // Try HTML5 geolocation.
      //   if (navigator.geolocation) {
      //     navigator.geolocation.getCurrentPosition(function(position) {
      //       var pos = {
      //         lat: position.coords.latitude,
      //         lng: position.coords.longitude
      //       };
      //       infoWindow.setPosition(pos);
      //       infoWindow.setContent('Location found.');
      //       infoWindow.open(map);
      //       map.setCenter(pos);
      //     }, function() {
      //       handleLocationError(true, infoWindow, map.getCenter());
      //     });
      //   } else {
      //     // Browser doesn't support Geolocation
      //     handleLocationError(false, infoWindow, map.getCenter());
      //   }
      // function handleLocationError(browserHasGeolocation, infoWindow, pos) {
      //   infoWindow.setPosition(pos);
      //   infoWindow.setContent(browserHasGeolocation ?
      //                         'Error: The Geolocation service failed.' :
      //                         'Error: Your browser doesn\'t support geolocation.');
      //   infoWindow.open(map);
      // }

    // Create gerenic map.
    // Create gerenic map.
    var mapArgs = {
      zoom: $el.data("zoom") || 16,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      styles: [
        {
          featureType: "all",
          elementType: "geometry",
          stylers: [
            {
              color: "#63A5E5"
            }
          ]
        },
        {
          featureType: "all",
          elementType: "geometry.fill",
          stylers: [
            {
              lightness: "-14"
            }
          ]
        },
        {
          featureType: "all",
          elementType: "geometry.stroke",
          stylers: [
            {
              lightness: "-100"
            },
            {
              gamma: "0.00"
            }
          ]
        },
        {
          featureType: "all",
          elementType: "labels.text.fill",
          stylers: [
            {
              gamma: 0.01
            },
            {
              lightness: "100"
            },
            {
              weight: "0.01"
            }
          ]
        },
        {
          featureType: "all",
          elementType: "labels.text.stroke",
          stylers: [
            {
              saturation: -31
            },
            {
              lightness: -33
            },
            {
              weight: 2
            },
            {
              gamma: 0.8
            }
          ]
        },
        {
          featureType: "all",
          elementType: "labels.icon",
          stylers: [
            {
              visibility: "off"
            }
          ]
        },
        {
          featureType: "landscape",
          elementType: "geometry",
          stylers: [
            {
              lightness: 30
            },
            {
              saturation: 30
            }
          ]
        },
        {
          featureType: "poi",
          elementType: "geometry",
          stylers: [
            {
              saturation: 20
            }
          ]
        },
        {
          featureType: "poi.park",
          elementType: "geometry",
          stylers: [
            {
              lightness: 20
            },
            {
              saturation: -20
            }
          ]
        },
        {
          featureType: "road",
          elementType: "geometry",
          stylers: [
            {
              lightness: 10
            },
            {
              saturation: -30
            }
          ]
        },
        {
          featureType: "road",
          elementType: "geometry.stroke",
          stylers: [
            {
              saturation: 25
            },
            {
              lightness: 25
            }
          ]
        },
        {
          featureType: "water",
          elementType: "all",
          stylers: [
            {
              lightness: -20
            }
          ]
        }
      ]
    };
    var map = new google.maps.Map($el[0], mapArgs);

    // Add markers.
    map.markers = [];
    $markers.each(function () {
      initMarker($(this), map);
    });

    // Center map based on markers.
    centerMap(map);

    // Return map instance.
    return map;
  }

  /**
   * initMarker
   *
   * Creates a marker for the given jQuery element and map.
   *
   * @date    22/10/19
   * @since   5.8.6
   *
   * @param   jQuery $el The jQuery element.
   * @param   object The map instance.
   * @return  object The marker instance.
   */
  var markersArr = [];

  window.openInfoBox = function(id) {
    $('.gm-ui-hover-effect').trigger('click');
    google.maps.event.trigger(markersArr[id], 'click');
  }
  window.closeAllInfoWindows = function() {
    for (var i=0;i<markersArr.length;i++) {
       markersArr[i].close();
    }
  }

  function initMarker($marker, map) {
    // Get position from marker.
    var lat = $marker.data("lat");
    var lng = $marker.data("lng");
    var latLng = {
      lat: parseFloat(lat),
      lng: parseFloat(lng)
    };
    var test = $marker.context.childNodes[1].innerText;
    var newTest = encodeURIComponent(test);
    var dirURL = 'https://www.google.com/maps/dir//' + newTest;
    // Create marker instance.
    var marker = new google.maps.Marker({
      position: latLng,
      map: map,
      icon: '/wp-content/themes/novelis/assets/images/icons/map_icon.png'
    });
    markersArr.push(marker);

    // Append to reference for later use.
    map.markers.push(marker);

    // If marker contains HTML, add it to an infoWindow.
    if ($marker.html()) {
      var contentString = '<a class="marker-directions" href="' + dirURL + '" target="_blank">DIRECTIONS <img src="/wp-content/themes/novelis/assets/images/icons/map-icon-nofiller.png" /></a>';
      // Create info window.
      var infowindow = new google.maps.InfoWindow({
        content: $marker.html() + contentString
      });

      // Show info window when marker is clicked.
      google.maps.event.addListener(marker, "click", function () {
        $('.gm-ui-hover-effect').trigger('click');
        infowindow.open(map, marker);
      });
    }
  }


  /**
   * centerMap
   *
   * Centers the map showing all markers in view.
   *
   * @date    22/10/19
   * @since   5.8.6
   *
   * @param   object The map instance.
   * @return  void
   */
  function centerMap(map) {
    // Create map boundaries from all map markers.
    var bounds = new google.maps.LatLngBounds();
    map.markers.forEach(function (marker) {
      bounds.extend({
        lat: marker.position.lat(),
        lng: marker.position.lng()
      });
    });

    // Case: Single marker.
    if (map.markers.length == 1) {
      map.setCenter(bounds.getCenter());

      // Case: Multiple markers.
    } else {
      map.fitBounds(bounds);
    }
  }

  // Render maps on page load.
  $(document).ready(function () {
    $(".acf-map").each(function () {
      var map = initMap($(this));
      google.maps.event.trigger(map, "resize");
      return map;
    });
  });
})(jQuery);
