const heroSlider = document.querySelector("#hero-slider");
// check to see if el exists first, if so, create the slider
if (heroSlider) {
  new Splide(heroSlider, {
    type: "loop",
    autoplay: true,
    interval: 4000,
    speed: 666,
    arrows: false
  }).mount();
}

const altSlider = document.querySelector("#alt-slider");
// check to see if el exists first, if so, create the slider
if (altSlider) {
  new Splide(altSlider, {
    type: "loop",
    autoplay: true,
    interval: 4000,
    speed: 666,
    arrows: false
  }).mount();
}
AOS.init();

