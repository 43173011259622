// burger time
// todo refactor, all kinds of DRY offenses in this file
/// @matt you up to it?

const hamburger = document.querySelector(".hamburger");
const search = document.querySelector(".search-form");
const mainNav = document.getElementById("main_menu");
const subNav = document.getElementById("top_menu");
const main = document.querySelector("main");
const globalFooter = document.querySelector("footer.global-footer");

hamburger.addEventListener("click", (e) => {
  hamburger.classList.toggle("is-active");
  if(search) {
    search.classList.toggle("open");
  }
  if(mainNav){
    mainNav.classList.toggle("open");
  }
  if(subNav){
    subNav.classList.toggle("open");
  }
  
  // locked prevents body scroll when mobile menu is open
  main.classList.toggle("locked");
  globalFooter.classList.toggle("locked");
});


// testing